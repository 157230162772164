import "./css/main.css";
function bindBurgerExpansion() {
    // Get all "navbar-burger" elements
    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach(function (el) {
            el.addEventListener('click', function () {
                // Get the target from the "data-target" attribute
                var target = el.dataset.target;
                var $target = document.getElementById(target);
                if (!$target) {
                    return;
                }
                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
            });
        });
    }
}
window.addEventListener('DOMContentLoaded', function (e) {
    var searchForm = document.getElementById('searchgame');
    if (searchForm) {
        searchForm.addEventListener('submit', function (e) {
            e.preventDefault();
            var data = new FormData(searchForm);
            var name = data.get('name');
            window.location.href = "/search?game=" + name;
        });
    }
    bindBurgerExpansion();
});
// document!.addEventListener('click', (e: Event) => { console.log('Click'); });
